import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './DeprecationMessage.module.css';

const cx = classNames.bind(styles);

export default function DeprecationMessage({ payHost }) {
  return (
    <div className={cx('deprecationMessage')}>
      IMPORTANT NOTICE: THE PAY WITH GASBUDDY<sup>®</sup> FUEL CARD WILL BE RETIRED IN 2025 AND YOU CAN SIGN UP FOR OUR <a href={`//${payHost}`}>NEW PAY WITH GASBUDDY+™ CARD HERE</a>
    </div>
  );
}

DeprecationMessage.propTypes = {
  payHost: PropTypes.string,
};

DeprecationMessage.defaultProps = {
  payHost: 'pay.gasbuddy.com',
};
