import { Checkbox, Constraint, FlexGrid, Form, Header, Input, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import checkEmailAddress from '../../../lib/utils/checkEmailAddress';
import isPostalCodeFromPayCountry from '../../../lib/utils/isPostalCodeFromPayCountry';
import isValidEmail from '../../../lib/utils/isValidEmail';
import isValidPostal from '../../../lib/utils/isValidPostal';
import { ANALYTICS_EVENTS, ANALYTICS_SCREENS } from '../../constants/analytics';
import PayPrograms from '../../constants/payPrograms';
import PayProgramContext from '../../context/payProgram';
import useTracking from '../../hooks/useTracking';
import CMSPartnerPropType from '../../prop-types/cmsPartner';
import FormActionButtons from '../FormActionButtons';
import Markup from '../Markup';
import styles from './WelcomeForm.module.css';

const cx = classnames.bind(styles);

export default function WelcomeForm({
  basePath,
  email: defaultEmail,
  error,
  isLoggedIn,
  maxCentsOff,
  partner,
  postal: defaultPostal,
  price,
  token,
}) {
  const [email, setEmail] = useState(defaultEmail);
  const [emailError, setEmailError] = useState('');
  const [postal, setPostal] = useState(defaultPostal);
  const [postalError, setPostalError] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const payProgram = useContext(PayProgramContext);
  const isPlus = payProgram === PayPrograms.Plus;
  const isPremium = payProgram === PayPrograms.Premium;

  const { trackEvent } = useTracking(ANALYTICS_SCREENS.PAY_ENROLL_START);

  const handleEmailBlur = useCallback(async () => {
    const emailSuggestion = await checkEmailAddress(email);

    if (emailSuggestion) {
      setEmailError(`Did you mean ${emailSuggestion}?`);
    }
  }, [email]);

  const handleEmailChange = useCallback((e) => {
    const { value } = e.target;
    setEmailError('');
    setEmail(value);
  }, []);

  const handlePostalChange = useCallback((e) => {
    const { value } = e.target;
    setPostalError('');
    setPostal(value);
  }, []);

  const handleTermsChange = useCallback(() => {
    trackEvent(
      ANALYTICS_EVENTS.PAY_IAM_TERMS_CHECKBOX_CLICKED,
      {
        checked: !termsChecked,
        screen: 'Welcome',
      },
    );
    setTermsChecked(!termsChecked);
    setTermsError('');
  }, [termsChecked, trackEvent]);

  const handleTermsLinkClick = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.PAY_IAM_TERMS_LINK_CLICKED);
  }, [trackEvent]);

  const handleFormSubmit = useCallback((e) => {
    if (isLoggedIn) {
      return;
    }

    let isValid = true;

    if (!isValidEmail(email)) {
      setEmailError('Invalid email address');
      isValid = false;
    }

    const [prefix, fullDomain] = email.split('@');
    if (prefix.length > 30 || prefix.length < 1) {
      setEmailError('Your email prefix must be between 1 and 30 characters long.');
      isValid = false;
    }

    if (fullDomain) {
      const [domain, extension] = fullDomain.split('.');
      if (fullDomain.length > 50 || domain.length < 1) {
        setEmailError('Your email domain must be between 1 and 50 characters long.');
        isValid = false;
      }

      if (!extension || extension?.length < 1) {
        setEmailError('Your email domain must have an extension.');
        isValid = false;
      }
    }

    if (!isValidPostal(postal)) {
      setPostalError('Invalid postal code');
      isValid = false;
    } else if (!isPostalCodeFromPayCountry(postal)) {
      setPostalError('Pay with GasBuddy is only available to US residents');
      isValid = false;
    }

    if (!termsChecked) {
      setTermsError('You must agree to the terms and conditions');
      isValid = false;
    }

    if (isValid) {
      setShowLoading(true);
    } else {
      e.preventDefault();
    }
  }, [isLoggedIn, email, postal, termsChecked]);

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);


  if (isLoggedIn) {
    return (
      <Redirect to={`${basePath}/start`} />
    );
  }

  let description;

  if (isPlus) {
    description = (
      <Fragment>
        Get even more savings today for only <strong>${price}/month</strong>! Save up to <strong>{maxCentsOff}¢/gal</strong>.
        That&apos;s major fuel savings on every gallon of gas you pump.
      </Fragment>
    );
  } else if (isPremium) {
    description = (
      <Fragment>
        Get even more savings today for only <strong>${price}/month</strong>!
        Save up to <strong>{maxCentsOff}¢/gal</strong>, get 24-hour roadside assistance, and access exclusive GasBack — free gas!
      </Fragment>
    );
  } else if (partner) {
    description = partner.intro ? (
      <Markup source={partner.intro} />
    ) : (
      <Fragment>
        Save up to <strong>{maxCentsOff}¢/gal</strong> with our <strong>free forever</strong> gas card.
        No bills, no hassles, and nothing to fund. <strong>Just savings.</strong>
      </Fragment>
    );
  } else {
    description = (
      <Fragment>
        Save up to <strong>{maxCentsOff}¢/gal</strong> with a GasBuddy card.
        Join over a million drivers who have saved more than $17 million at the pump!
      </Fragment>
    );
  }

  const isMembership = isPlus || isPremium;
  const title = `Get started saving today!${(partner && !isMembership) ? ' It\'s free to join.' : ''}`;

  return (
    <Constraint className={cx('welcomeForm')} padded desktop={10}>
      <Form
        aria-label="Pay with GasBuddy Welcome Form"
        action={`${basePath}/start`}
        method="post"
        onSubmit={handleFormSubmit}
        loading={showLoading}
      >
        <Header as="h1" className={cx('title')}>{title}</Header>
        <Text as="p" className={cx('bodyCopy')}>
          {description}
        </Text>
        <input type="hidden" name="skipWelcome" value="1" />
        <input type="hidden" name="token" value={token} />
        <FlexGrid className={cx('welcomeGrid')} container>
          <FlexGrid.Column tablet={6} desktop={7} className={cx('field')}>
            <Input
              autoFocus={!token}
              className={cx('formInput')}
              data-testid="emailInput"
              error={emailError}
              label="Email"
              name="email"
              onBlur={handleEmailBlur}
              onChange={handleEmailChange}
              readOnly={!!token}
              type="email"
              value={email}
            />
          </FlexGrid.Column>
          <FlexGrid.Column tablet={6} desktop={5} className={cx('field')}>
            <Input
              autoFocus={!!token}
              className={cx('formInput')}
              data-testid="postalInput"
              error={postalError}
              label="Postal Code"
              name="postal"
              onChange={handlePostalChange}
              value={postal}
            />
          </FlexGrid.Column>
        </FlexGrid>
        <div className={cx('checkboxWrapper')}>
          <Checkbox
            id="terms_checkbox"
            name="termsChecked"
            checked={termsChecked}
            onChange={handleTermsChange}
            label={(
              <Text className={cx('disclaimer')}>
                I agree to GasBuddy’s <a href="https://www.gasbuddy.com/disclaimer" rel="noopener noreferrer" target="_blank" onClick={handleTermsLinkClick} className={cx('textLink')}> Terms&nbsp;and&nbsp;Conditions</a><sup>*</sup>
              </Text>
            )}
          />
        </div>
        <br />
        {(termsError || error) && (
          <Text as="p" color="red">
            {termsError || error}
          </Text>
        )}
        <FormActionButtons
          canSubmit={isLoggedIn || (!!email && !!postal && !!termsChecked)}
          primaryButtonText={`Get The ${(!isPlus && !isPremium && 'Free ') || ''}Card`}
          stepName="Welcome"
        />
      </Form>
    </Constraint>
  );
}

WelcomeForm.propTypes = {
  basePath: PropTypes.string,
  error: PropTypes.string,
  email: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  maxCentsOff: PropTypes.number,
  partner: CMSPartnerPropType,
  postal: PropTypes.string,
  price: PropTypes.number,
  token: PropTypes.string,
};

WelcomeForm.defaultProps = {
  basePath: '',
  error: undefined,
  email: '',
  isLoggedIn: false,
  maxCentsOff: 25,
  partner: undefined,
  postal: '',
  price: undefined,
  token: '',
};
