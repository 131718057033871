export const ANALYTICS_SCREENS = {
  PAY_ENROLL_START: 'Pay_Web_Enroll_Start',
  PAY_ENROLL_LOGIN: 'Pay_Web_Enroll_Login',
  PAY_ENROLL_SHIPPING: 'Pay_Web_Enroll_Shipping',
  PAY_ENROLL_IDENTITY: 'Pay_Web_Enroll_Identity',
  PAY_ENROLL_BANK_START: 'Pay_Web_Enroll_Bank_Start',
  PAY_ENROLL_BANK_SEARCH: 'Pay_Web_Enroll_Bank_Search',
  PAY_ENROLL_BANK_MICRODEPOSIT: 'Pay_Web_Enroll_Bank_MicroDeposit',
  PAY_ENROLL_CONFIRM: 'Pay_Web_Enroll_Confirm',
  PAY_ENROLL_COMPLETE: 'Pay_Web_Enroll_Complete',
  PAY_ENROLL_CREDIT_COMPLETE: 'Pay_Web_Enroll_Credit_Complete',
  PAY_PREMIUM_PURCHASE_CREDIT_CARD: 'Pay_Web_Premium_Purchase_CC',
  PAY_PREMIUM_BILLING_OPTIONS: 'Pay_Web_Enroll_Plan_Selection',
  PAY_PREMIUM_UPGRADE: 'Pay_Web_Membership_Purchase',
  PAY_PREMIUM_UPGRADED: 'Pay_Web_Membership_Complete',
  PAY_EXPIRED_CARD_ADDRESS_CONFIRMED: 'Pay_Web_Card_Expiration_Confirm_Address_Complete_Opened',
  PAY_REDEEM_TYPES: 'Pay_Web_Redeem_Types',
  PAY_REDEEM_CHARITY_SELECT: 'Pay_Web_Redeem_Charity_Select',
  PAY_REDEEM_CHARITY_REVIEW: 'Pay_Web_Redeem_Charity_Review',
  PAY_REDEEM_CHARITY_COMPLETE: 'Pay_Web_Redeem_Charity_Complete',
  PAY_REDEEM_GIFTCARD_SELECT: 'Pay_Web_Redeem_GiftCard_Select',
  PAY_REDEEM_GIFTCARD_DETAILS: 'Pay_Web_Redeem_GiftCard_Details',
  PAY_REDEEM_GIFTCARD_REVIEW: 'Pay_Web_Redeem_GiftCard_Review',
  PAY_REDEEM_GIFTCARD_PENDING: 'Pay_Web_Redeem_GiftCard_Pending',
  PAY_REDEEM_GIFTCARD_PURCHASE: 'Pay_Web_Redeem_GiftCard_Purchase',
  PAY_REDEEM_GIFTCARD_COMPLETE: 'Pay_Web_Redeem_GiftCard_Complete',
  PAY_REWARD_CODE_REDEEM: 'Pay_Reward_Code_Redemption',
};

export const ANALYTICS_EVENTS = {
  PAY_ENROLL_BANK_SEARCH_COMPLETE: 'Pay_Web_Enroll_Bank_Search_Complete',
  PAY_ENROLL_BANK_SEARCH_EXIT: 'Pay_Web_Enroll_Bank_Search_Exit',
  PAY_ENROLL_BANK_SEARCH_ERROR: 'Pay_Web_Enroll_Bank_Search_Error',
  PAY_ENROLL_BANK_SEARCH_NO_ACCOUNTS_FOUND: 'Pay_Web_Enroll_Bank_Search_No_Accounts_Found',
  PAY_ENROLL_BANK_SEARCH_RESULT_TAPPED: 'Pay_Enroll_Bank_Search_Result_Tapped',
  PAY_ENROLL_BANK_LOGIN: 'Pay_Enroll_Bank_Login',
  PAY_ENROLL_BANK_NOT_FOUND: 'Pay_Enroll_Bank_Not_Found',
  PAY_ENROLL_CONFIRM_SUBMIT_TAPPED: 'Pay_Web_Enroll_Confirm_Submit_Tapped',
  PAY_ENROLL_UPGRADE_SUBMIT_TAPPED: 'Pay_Web_Upgrade_Confirm_Submit_Clicked',
  PAY_ENROLL_EDIT_TAPPED: 'Pay_Web_Enroll_Edit_Tapped',
  PAY_ENROLL_CANCEL_TAPPED: 'Pay_Web_Enroll_Canceled_Tapped',
  PAY_REDEEM_BALANCE_INFO_CLICKED: 'Pay_Web_Redeem_Balance_Info_Clicked',
  PAY_REDEEM_TYPES_SUBMIT_CLICKED: 'Pay_Web_Redeem_Types_Submit_Clicked',
  PAY_REDEEM_TYPES_CANCEL_CLICKED: 'Pay_Web_Redeem_Types_Cancel_Clicked',
  PAY_REDEEM_CHARITY_SELECT_SUBMIT_CLICKED: 'Pay_Web_Redeem_Charity_Select_Submit_Clicked',
  PAY_REDEEM_CHARITY_SELECT_CANCEL_CLICKED: 'Pay_Web_Redeem_Charity_Select_Cancel_Clicked',
  PAY_REDEEM_CHARITY_REVIEW_SUBMIT_CLICKED: 'Pay_Web_Redeem_Charity_Review_Submit_Clicked',
  PAY_REDEEM_CHARITY_REVIEW_CANCEL_CLICKED: 'Pay_Web_Redeem_Charity_Review_Cancel_Clicked',
  PAY_REDEEM_CHARITY_COMPLETE_CONTINUE_CLICKED: 'Pay_Web_Redeem_Charity_Complete_Continue_Clicked',
  PAY_REDEEM_GIFTCARD_SELECT_SUBMIT_CLICKED: 'Pay_Web_Redeem_GiftCard_Select_Submit_Clicked',
  PAY_REDEEM_GIFTCARD_SELECT_CANCEL_CLICKED: 'Pay_Web_Redeem_GiftCard_Select_Cancel_Clicked',
  PAY_REDEEM_GIFTCARD_DETAILS_SUBMIT_CLICKED: 'Pay_Web_Redeem_GiftCard_Details_Submit_Clicked',
  PAY_REDEEM_GIFTCARD_DETAILS_CANCEL_CLICKED: 'Pay_Web_Redeem_GiftCard_Details_Cancel_Clicked',
  PAY_REDEEM_GIFTCARD_REVIEW_WRONG_EMAIL: 'Pay_Web_Redeem_GiftCard_Review_Wrong_Email_Clicked',
  PAY_REDEEM_GIFTCARD_REVIEW_SUBMIT_CLICKED: 'Pay_Web_Redeem_GiftCard_Review_Submit_Clicked',
  PAY_REDEEM_GIFTCARD_REVIEW_CANCEL_CLICKED: 'Pay_Web_Redeem_GiftCard_Review_Cancel_Clicked',
  PAY_REDEEM_GIFTCARD_PENDING_CONTINUE_CLICKED: 'Pay_Web_Redeem_GiftCard_Pending_Continue_Clicked',
  PAY_REDEEM_GIFTCARD_PURCHASE_SUBMIT_CLICKED: 'Pay_Web_Redeem_GiftCard_Purchase_Submit_Clicked',
  PAY_REDEEM_GIFTCARD_COMPLETE_CONTINUE_CLICKED: 'Pay_Web_Redeem_GiftCard_Complete_Continue_Clicked',
  PAY_REWARD_CODE_REDEEM_CODE_SUBMITTED: 'Pay_Reward_Code_Redemption_Code_Submitted',
  PAY_REWARD_CODE_REDEEM_SUBMISSION_FAILED: 'Pay_Reward_Code_Redemption_Submission_Failed',
  PAY_REWARD_CODE_REDEEM_SUBMISSION_SUCCEEDED: 'Pay_Reward_Code_Redemption_Submission_Succeeded',
  PAY_REWARD_CODE_REDEEM_SUBMIT_ANOTHER_CODE_CLICKED: 'Pay_Reward_Code_Redemption_Submit_Another_Code_Clicked',
  PAY_IAM_TERMS_CHECKBOX_CLICKED: 'Pay_IAM_Terms_Checkbox_Clicked',
  PAY_IAM_TERMS_LINK_CLICKED: 'Pay_IAM_Terms_Link_Clicked',
  LOGGED_IN: 'IAM_User_Successfully_Logged_In', // Same as identity-web
  REGISTERED: 'IAM_User_Successfully_Registered', // Same as identity-web
  PARTNER_OFFER_ACCEPT_CLICKED: 'Pay_Web_Enroll_Partner_Offer_Accept_Clicked',
  PARTNER_OFFER_DECLINE_CLICKED: 'Pay_Web_Enroll_Partner_Offer_Decline_Clicked',
};

export const LOCALYTICS_CUSTOM_DIMENSIONS = {
  USER_ID: 14,
  USER_NAME: 15,
};
